<div class="worker">
   <div class="worker__background worker__background--1"></div>
   <div class="worker__background worker__background--2"></div>
   <div class="worker__wip">Work In Progress...</div>

   <header class="header">
      <nav class="nav container justify-content-between">
         <div class="nav__logo">
            <!-- <img class="logo" src="/assets/media/kampotenten-logo.jpg"> -->
            <span>Kampo</span>Tenten
         </div>
         <div class="nav__socials">
            <a href="https://www.instagram.com/kampotenten/" target="blank" title="Instagram"><i class="bi bi-instagram"></i></a>
         </div>
      </nav>
   </header>
   
   <div class="worker__section">
      <section class="container">
         <h1>STRETCH<br>TENTEN</h1>
         <p>Stretchtenten, planché/ tentvloer en verlichting. Binnenkort hier verkrijgbaar!</p>
         <a class="url mt-3 p-1 d-inline-block" href="mailto:info@kampo.be">info@kampo.be</a>
      </section>
   </div>

   <footer class="footer">
      <div class="footer__logo">
         <img class="logo" src="/assets/media/kampotenten-logo.jpg">
      </div>
      <div class="footer__disclaimer">

      </div>
   </footer>
</div>